import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import exclamationPointWarningTemplate from './exclamation-point-warning-template.html'
import * as Utils from './messageComponentUtils'

const HTMLExclamationPointWarningTemplate = Utils.loadHtmlTemplate(exclamationPointWarningTemplate, "ExclamationPointWarning#loadTemplate")

class ExclamationPointWarning extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLExclamationPointWarningTemplate, "ExclamationPointWarningComponent#connectedCallback")
  }

  render() {
    // No extra steps for this one.
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('exclamation-point-warning', ExclamationPointWarning);
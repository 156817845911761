import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import loadingHourglassTemplate from './loading-hourglass-template.html'
import * as Utils from './messageComponentUtils'

const HTMLLoadingHourglassTemplate = Utils.loadHtmlTemplate(loadingHourglassTemplate, "LoadingHourglass#loadTemplate")

class LoadingHourglass extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLLoadingHourglassTemplate, "LoadingHourglassComponent#connectedCallback")
  }

  render() {
    // No extra steps for this one.
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('loading-hourglass', LoadingHourglass);
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import popdownTemplate from './popdown-message-template.html'
import * as Utils from './messageComponentUtils'

const HTMLPopdownTemplate = Utils.loadHtmlTemplate(popdownTemplate, "PopdownMessage#loadTemplate")

class PopdownMessage extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLPopdownTemplate, "popdownMessageComponent#connectedCallback")
  }

  render() {
    Utils.render.bind(this)("popdownMessageComponent#render")
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('popdown-message', PopdownMessage);
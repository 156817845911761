import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'


export interface MessageWebComponent extends HTMLElement {
  connectedCallback: (template: HTMLTemplateElement | null, context: string) => void
  render: (context: string) => void
  removeFromPage: () => void
}

export function loadHtmlTemplate(htmlString: string, context: string) {
  const parsedHtml = new DOMParser().parseFromString(htmlString, 'text/html')

  if (parsedHtml) {
    return parsedHtml.querySelector('template')
  } else {
    console.error(`Could not parse HTML from html string for ${context}.`)
    return null
  }
}


export function connectedCallback(this: MessageWebComponent, template: HTMLTemplateElement | null, context: string) {
  const shadowRoot = this.attachShadow({ mode: 'open' });

  if (shadowRoot && template) {
    // Clone the template and the cloned node to the shadowDOM's root.
    const instance = template.content.cloneNode(true);
    shadowRoot.appendChild(instance);

    this.render(context)
  } else {
    if (!template) console.error(`Missing template in ${context}`)
    if (!shadowRoot) console.error(`Missing shadowRoot in ${context}`)
  }
}

export function render(this: MessageWebComponent, context: string) {
  if (this.shadowRoot) {
    this.shadowRoot.addEventListener('click', function (this: MessageWebComponent, event: Event) {
      if ((<HTMLElement>event.target).id !== 'remove-button') return
      this.removeFromPage()
    }.bind(this));
  } else {
    if (!this.shadowRoot) console.error(`Missing this.shadowRoot in ${context}`)
  }
}

export function removeFromPage(this: MessageWebComponent) {
  this.remove()
}
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import exclamationPointWarningTemplate from './success-message-box-template.html'
import * as Utils from './messageComponentUtils'

const HTMLSuccessMessageBoxTemplate = Utils.loadHtmlTemplate(exclamationPointWarningTemplate, "SuccessMessageBox#loadTemplate")

class SuccessMessageBox extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLSuccessMessageBoxTemplate, "SuccessMessageBoxComponent#connectedCallback")
  }

  render() {
    // No extra steps for this one.
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('success-message-box', SuccessMessageBox);
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import exclamationPointWarningTemplate from './info-message-box-template.html'
import * as Utils from './messageComponentUtils'

const HTMLInfoMessageBoxTemplate = Utils.loadHtmlTemplate(exclamationPointWarningTemplate, "InfoMessageBox#loadTemplate")

class InfoMessageBox extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLInfoMessageBoxTemplate, "InfoMessageBoxComponent#connectedCallback")
  }

  render() {
    // No extra steps for this one.
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('info-message-box', InfoMessageBox);
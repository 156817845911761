import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
// @ts-ignore
import popupErrorTemplate from './popup-error-template.html'
import * as Utils from './messageComponentUtils'

const HTMLPopupErrorTemplate = Utils.loadHtmlTemplate(popupErrorTemplate, "PopupError#loadTemplate")

class PopupError extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    Utils.connectedCallback.bind(this)(HTMLPopupErrorTemplate, "popupErrorComponent#connectedCallback")
  }

  render() {
    Utils.render.bind(this)("popupErrorComponent#render")
  }

  removeFromPage() {
    Utils.removeFromPage.bind(this)()
  }
}
customElements.define('popup-error', PopupError);